<template>
  <div class="container">
    <div class="leftBox">
      <div class="fileBox">
        <el-form>
          <el-form-item>
            <el-select v-model="form.name" placeholder="请选择" style="width: 100%">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-row :gutter="2">
              <el-col :span="19">
                <el-input placeholder="请输入文件名称"></el-input>
              </el-col>
              <el-col :span="5">
                <el-button type="primary">筛选</el-button>
              </el-col>
            </el-row>
          </el-form-item>
        </el-form>
        <div class="fileList">
          <el-tree
            :data="data"
            :props="defaultProps"
            highlight-current
            default-expand-all
            :expand-on-click-node="false"
            ref="tree"
          >
            <div class="custom-tree-node" slot-scope="{ node,data }">
              <img
                v-if="data.children"
                width="18px"
                src="../../../assets/optimizeImgs/folder.png"
                alt
              />
              <i class="el-icon-document" v-else></i>
              <span>{{ node.label }}</span>
            </div>
          </el-tree>
        </div>
      </div>
    </div>
    <div class="rightBox">
      <div class="queryBox">
        <el-button type="info" plain>保存修改</el-button>
      </div>
      <el-table :data="tableData" border style="width: 100%" height="calc(100% - 102px)">
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column label="输入">
          <el-table-column label="输入过程"></el-table-column>
          <el-table-column label="名称"></el-table-column>
          <el-table-column label="输入类型"></el-table-column>
        </el-table-column>
        <el-table-column prop="userName" label="本过程"></el-table-column>
        <el-table-column label="输出">
          <el-table-column label="名称"></el-table-column>
          <el-table-column label="输出类型"></el-table-column>
          <el-table-column label="输出过程"></el-table-column>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-sizes="[10, 20, 30, 40, 50, 100]"
        :page-size="limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import { get, post, put, deleteMethod } from "../../../api/http";
export default {
  data() {
    return {
      options: [],
      data: [
        {
          label: "全部文件",
          children: [
            {
              label: "文件 AAAAAAAA",
            },
            {
              label: "文件 AAAAAAAA",
            },
            {
              label: "文件 AAAAAAAA",
            },
            {
              label: "文件 AAAAAAAA",
            },
          ],
        },
      ],
      defaultProps: {
        children: "children",
        label: "label",
      },
      form: {},
      page: 1,
      limit: 10,
      total: 0,
      tableData: [],
      isMore: true,
      companyList:[],
    };
  },
  computed: {
    displayText() {
      return this.isMore ? '更多筛选 >>>' : '<<< 返回文件';
    }
  },
  created() {
    this.getCompanyList();
  },
  mounted() {},
  methods: {
    // 获取公司列表
    getCompanyList() {
      get('/api/Company/GetAll').then(res =>{
        if(res.code == 200){
          this.companyList = res.data;
        }
      })
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../../assets/style/optimizeTable.less";
.container {
  width: 100%;
  height: 100%;
  background: #e7e6eb;
  display: flex;
  .leftBox {
    width: 272px;
    height: 100%;
    background: #ffffff;
    border-radius: 2px;
    border: 1px solid #cfcfcf;
    margin-right: 6px;
    padding-bottom: 62px;
    box-sizing: border-box;
   
    .fileBox {
      height: 100%;
      border-radius: 2px;
      .el-form{
        padding: 12px 12px 2px 12px;
        box-sizing: border-box;
      }
      .el-form-item {
        margin-bottom: 4px;
      }
      ::v-deep .el-input__inner {
        height: 40px !important;
      }
      .fileList {
        background: #f9f9f9;
        margin-top: 12px;
        height: calc(100% - 118px);
        overflow-y: auto;
        .el-tree {
          position: relative;
          background-color: #f9f9f9;
          ::v-deep .el-tree-node__expand-icon {
            font-size: 18px;
            vertical-align: middle;
          }
          ::v-deep .el-tree-node__content {
            display: flex;
            align-items: center !important;
          }
          .custom-tree-node {
            display: flex;
            align-items: center;
            img {
              vertical-align: middle;
            }
            span {
              vertical-align: middle;
              margin-left: 5px;
              font-family: Microsoft YaHei;
              font-weight: bold;
              font-size: 14px;
              color: #000000;
            }
          }
          ::v-deep .el-tree-node:before {
            position: absolute;
            top: 26px;
            left: 34px;
            height: calc(100% - 42px);
            border-left: 1px solid #dbdbdb;
            content: "";
          }
          ::v-deep .el-tree-node__children {
            position: relative;
            .el-tree-node__content:after {
              position: absolute;
              left: 34px;
              width: 10px;
              border-top: 1px solid #dbdbdb;
              content: "";
            }
            .custom-tree-node {
              span {
                font-weight: 400;
              }
            }
          }
        }
      }
    }
    .btnBox{
      width: 100%;
      text-align: center;
      margin-top: 10px;
    }
  }
  .rightBox {
    width: calc(100% - 278px);
    height: 100%;
    background: #ffffff;
    .queryBox {
      padding: 12px 0 10px 10px;
    }
  }
}
</style>